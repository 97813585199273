<template>
  <el-tabs style="height: 100%;" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="小规模增值税及附加税费申报表">
      <div class="right_box" v-if="!this.paramCheck.comId">
        <el-button size="small" @click="printingNew()" type="success">打印</el-button>
        <el-button type="primary" @click="download()" size="small">导出</el-button>
      </div>
      <mainTable title="小规模增值税及附加税费申报表" ref="maintable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="增值税及附加税费申报表（小规模适用）附加资料（一）" v-if="showTable || this.paramCheck.comId">
      <fbOne title="增值税及附加税费申报表（小规模适用）附加资料（一）" ref="fbOne" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbOne>
    </el-tab-pane>
    <el-tab-pane label="增值税及附加税费申报表（小规模适用）附加资料（二）" v-if="showTable || this.paramCheck.comId">
      <fbTwo title="增值税及附加税费申报表（小规模适用）附加资料（二）" ref="fbTwo" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbTwo>
    </el-tab-pane>
    <el-tab-pane label="增值税减免税申报明细表">
      <reduction title="增值税减免税申报明细表" ref="fbThree" :startAccountPeriod="startAccountPeriod" :comId="comId" :source ="source"></reduction>
    </el-tab-pane>
    <el-tab-pane label="增值税开票信息/销售额" v-if="showTable || this.paramCheck.comId">
      <fbFour title="增值税开票信息/销售额" ref="fbFour" :startAccountPeriod="startAccountPeriod" :comId="comId" ></fbFour>
    </el-tab-pane>
    <el-tab-pane label="增值税税额" v-if="showTable || this.paramCheck.comId">
      <fbFive title="增值税税额" ref="fbFive" :startAccountPeriod="startAccountPeriod" :comId="comId" ></fbFive>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mainTable from "./components/mainTable.vue"
import fbOne from "./components/fb1.vue"
import fbTwo from "./components/fb2.vue"
import reduction from "./components/reduction.vue" 
import fbFour from "./components/fb4.vue"
import fbFive from "./components/fb5.vue"

import { report ,exportReport } from "@/api/printSet"

export default {
  name:'smallVat',
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  components:{
    mainTable,
    fbOne,
    fbTwo,
    reduction,
    fbFour,
    fbFive
  },
  watch:{
    paramCheck(newValue,oldValue){
      this.comId = newValue.comId
      this.startAccountPeriod = newValue.period
      this.$nextTick(() => {
        this.getList()
      });
    },
    immediate:true,
    deep:true,
  },
  data() {
    return {
      startAccountPeriod:'',
      comId:0,
      type:'',
      showTable:this.$store.getters["user/comInfo"].comKj,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_small_vat']
      },
      source:""
    }
  },
  created(){
    if(this.paramCheck.comId){
      // //console.log("查看报表");
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId
    }else{
      // //console.log("初始化");
      if(this.$store.getters["commons/params"].type){
        this.type = this.$store.getters["commons/params"].type
        this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
        this.comId = this.$store.getters["commons/params"].comId
        this.param.comIds = [this.$store.getters["commons/params"].comId];
        this.param.period = this.$store.getters["commons/params"].startAccountPeriod
        this.param.itemName = 'swcsh';
        this.source = 'swcsh'
      }
    }
    
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '小规模增值税及附加税费申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '增值税及附加税费申报表（小规模适用）附加资料（一）'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '增值税及附加税费申报表（小规模适用）附加资料（二）'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '增值税减免税申报明细表'){
        this.$refs.fbThree.getList()
      }else if(tab.props.label == '增值税开票信息/销售额'){
        this.$refs.fbFour.getList()
      }else if(tab.props.label == '增值税税额'){
        this.$refs.fbFive.getList()
      }
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    getList(){
      this.$refs.maintable.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
</style>