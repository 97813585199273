<template>
  <div class="big_box4">
    <div class="top_title3">
      <h4>{{title}}</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <table class="content" cellspacing="0" width="100%" >
      <tr class="top_bg">
        <td class="center" style="width:4%">编号</td>
        <td colspan="2" class="center" style="width:32%">核定方法</td>
        <td class="center" style="width:32%">当期允许抵扣农产品增值税进项税额（元）</td>
        <td class="center" style="width:32%">备注</td>
      </tr>
      
      <tr>
        <td class="center bg_color" rowspan="2">1</td>
        <td class="center bg_color" rowspan="2">以购进农产品为原料生产货物</td>
        <td class="center bg_color">投入产出法</td>
        <qzf-str-td type="text" v-model:amount="this.hzList.trccf" @change="changeList()"></qzf-str-td>
        <td><input type="text" v-model="this.hzList.trccfRemark"></td>
      </tr>
      <tr>
        <td class="center bg_color">成本法</td>
        <qzf-str-td type="text" v-model:amount="this.hzList.cbf" @change="changeList()"></qzf-str-td>
        <td><input type="text" v-model="this.hzList.cbfRemark"></td>
      </tr>
      <tr>
        <td class="center bg_color">2</td>
        <td class="center bg_color" colspan="2">购进农产品直接销售</td>
        <qzf-str-td type="text" v-model:amount="this.hzList.gjncpzjxs" @change="changeList()"></qzf-str-td>
        <td><input type="text" v-model="this.hzList.gjncpzjxsRemark"></td>
      </tr>
      <tr>
        <td class="center bg_color">3</td>
        <td class="center bg_color" colspan="2">购进农产品用于生产经营且不构成货物实体</td>
        <qzf-str-td type="text" v-model:amount="this.hzList.gjncpyysc" @change="changeList()"></qzf-str-td>
        <td><input type="text" v-model="this.hzList.gjncpyyscRemark"></td>
      </tr>
      <tr>
        <td class="center bg_color" colspan="3">合计</td>
        <td class="right" style="  line-height: 34px;padding-right:17px;font-size: 14px;color: #333;">{{sumAmount}}</td>
        <td></td>
      </tr>
    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button @click="saveFb9" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
    <qzf-button @success="saveFb9" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name: 'deduct',
  props: {
    title:String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  computed: {
    //金额合计
    sumAmount() {
      let count = 0;
      // this.hzList.map(v => {
      //   count = Number(v.trccf*1 + v.cbf*1 + v.gjncpzjxs*1 + v.gjncpyysc*1)
      // });
      count = this.hzList.trccf*1 +this.hzList.cbf*1 +this.hzList.gjncpzjxs*1 +this.hzList.gjncpyysc*1 
      return Number(count.toFixed(2));
    },
  },
  data() {
    return {
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      hzList:{cbf: "",cbfRemark: "",gjncpyysc: "",gjncpyyscRemark: "",gjncpzjxs: "",gjncpzjxsRemark: "",trccf: "",trccfRemark: ""},
    }
  },
  created() {
    // 获取窗口高度
    this.contentStyleObj=this.$getHeight(400)
  },
  methods: {
    changeList(){

    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb9',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.hzList = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb9'}).then(res=>{
          if(res.data.data.info){
            this.hzList = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb9(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb9',
          items:[this.hzList],
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb9',
          items:[this.hzList]
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  // width: 98%;
  //padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
</style>