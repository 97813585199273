<template>
  <el-scrollbar :style="{height:contentStyleObj}">
  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
    <div class="top_title3">
      <h4>{{title}}</h4>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <div class="cent">
      <div class="add" @click="handleAddItem(ncpList,index)">
      <i class="iconfont icon-jiahao"></i>
      <!-- <svg-icon icon-class="add" /> -->
    </div>
    <table class="content" cellspacing="0" width="100%">
      <tr class="top_bg">
        <td rowspan="2" class="center" style="width:4%">编号</td>
        <td rowspan="2" class="center" style="width:12%">产品名称</td>
        <td rowspan="2" class="center" style="width:10%">耗用农产品名称</td>
        <td class="center" >核定的单耗数量（吨）</td>
        <td class="center" >期初库存农产品数量（吨）</td>
        <td class="center" >期初平均买价（元/吨）</td>
        <td class="center" >当期购进农产品数量（吨）</td>
        <td class="center" >当期买价（元/吨）</td>
        <td class="center" >平均购买单价（元/吨）</td>
        <td class="center" >当期销售货物数量（吨）</td>
        <td class="center" >扣除率</td>
        <td class="center" >当期允许抵扣农产品进项税额（元）</td>
      </tr>
      <tr class="top_bg">
        <td style="width:8%">L1</td>
        <td style="width:8%">L2</td>
        <td style="width:8%">L3</td>
        <td style="width:8%">L4</td>
        <td style="width:8%">L5</td>
        <td style="width:8%">L6=(L2*L3+L4*L5)/(L2+L4)</td>
        <td style="width:8%">L7</td>
        <td style="width:6%">L8</td>
        <td style="width:12%">L9=L7*L1*L6*L8/(1+L8)</td>
      </tr>
      <tr v-for="(item, $index) in ncpList" :key="$index" class="position_tr">
          <td class="center bg_color">{{$index + 1}}</td>
          <td class="new_input">
            <el-select @change="changeProduct" v-model="item.name" placeholder="请选择产品名称" size="small" filterable style="width:100%" clearable>
              <el-option
                v-for="item in productOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td class="new_input"><input type="text" v-model="item.hyName"></td>
          <qzf-str-td type="text" v-model:amount="item.l1" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l2" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l3" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l4" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l5" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l6" :disabled="expStatus"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l7" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l8" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l9" :disabled="expStatus"></qzf-str-td>
        <div class="close" @click="handleDelItem(ncpList,$index)">
          <i class="iconfont icon-shanchu"></i> 
        </div>
      </tr>
      <tr>
        <td colspan="9" class="center">合计</td>
        <td class="center">{{sumAmount}}</td>
        <td class="center">---</td>
        <td class="center">---</td>
      </tr>
    </table>
    </div>
    
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button @click="saveFb8" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
    <qzf-button @success="saveFb8" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
  </div>
  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus2">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
    <div class="top_title3">
      <h4>购进农产品直接销售核定农产品增值税进项税额计算表</h4>
    </div>
    <div class="cent">
      <div class="add" @click="handleAddItem2(ncpList2,index)">
        <i class="iconfont icon-jiahao"></i>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
          <td rowspan="2" class="center" style="width:4%">序号</td>
          <td rowspan="2" class="center" style="width:12%">产品名称</td>
          <td class="center" >当期销售农产品数量(吨)</td>
          <td class="center" >损耗数量</td>
          <td class="center" >农产品购进数量</td>
          <td class="center" >损耗率(%)</td>
          <td class="center" >期初库存农产品数量(吨)</td>
          <td class="center" >期初平均买价(元/吨)）</td>
          <td class="center" >当期购进农产品数量(吨)</td>
          <td class="center" >当期买价(元/吨)</td>
          <td class="center" >农产品平均购买单价(元/吨)</td>
          <td class="center" >扣除率</td>
          <td class="center" >当期允许抵扣农产品进项税额(元)</td>
        </tr>
        <tr class="top_bg">
          <td style="width:7%">L1</td>
          <td style="width:7%">L2</td>
          <td style="width:7%">L3</td>
          <td style="width:7%">L4=l2/L3</td>
          <td style="width:7%">L5</td>
          <td style="width:8%">L6</td>
          <td style="width:8%">L7</td>
          <td style="width:8%">L8</td>
          <td style="width:9%">L9=(L5*L6+L7*L8)/(L5+L7)</td>
          <td style="width:8%">L10</td>
          <td style="width:8%">L11=L1/(1-L4)*L9*L10/(1+L10)</td>

        </tr>
        <tr v-for="(item, $index) in ncpList2" :key="$index" class="position_tr">
          <td class="center bg_color">{{$index + 1}}</td>
          <td class="new_input">
            <el-select @change="changeProduct" v-model="item.name" placeholder="请选择产品名称" size="small" filterable style="width:100%" clearable>
              <el-option
                v-for="item in productOption2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </td>
          <qzf-str-td type="text" v-model:amount="item.l1" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l2" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l3" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l4" :disabled="expStatus2"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l5" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l6" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l7" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l8" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l9" :disabled="expStatus2" ></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l10" @change="changeList()"></qzf-str-td>
          <qzf-str-td type="text" v-model:amount="item.l11" :disabled="expStatus2"></qzf-str-td>
          <div class="close" @click="handleDelItem2(ncpList2,$index)">
            <i class="iconfont icon-shanchu"></i> 
          </div>
        </tr>
        <tr>
          <td colspan="10" class="center">合计</td>
          <td class="center">{{sumAmount2}}</td>
          <td class="center">---</td>
          <td class="center">---</td>
        </tr>
      </table>
    </div>
    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button @click="saveFb11" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button @success="saveFb11" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>
</el-scrollbar>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'farmTable',
  props: {
    title:String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
  },
  computed: {
    //金额合计
    sumAmount() {
      let count = 0;
        this.ncpList.map(v => {
          if (v.l7 == undefined){
            v.l7 = 0
          }
          count += v.l7*1
        })
      return Number(count);
    },
    sumAmount2() {
      let count2 = 0;
      //console.log(this.ncpList2);
      this.ncpList2.map(v => {
        //console.log(v.l9);
        if (v.l9 == undefined){
          v.l9 = 0
        }
        count2 += v.l9*1
      })
      //console.log(count2);
      return Number(count2);
    },
  },
  data() {
    return {
      expStatus: true,
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      ncpList:[{}],
      productOption:[
        {
          value: '13300000|植物油及其制品',
          label: '13300000|植物油及其制品'
        },
        {
          value: '14401101|超高温灭菌牛乳（每吨）',
          label: '14401101|超高温灭菌牛乳（每吨）'
        },
        {
          value: '14401102|超高温灭菌牛乳（蛋白质含量≥3.3%）（每吨）',
          label: '14401102|超高温灭菌牛乳（蛋白质含量≥3.3%）（每吨）'
        },
        {
          value: '14401103|巴氏杀菌牛乳（每吨）',
          label: '14401103|巴氏杀菌牛乳（每吨）'
        },
        {
          value: '14401104|巴氏杀菌牛乳（蛋白质含量≥3.3%）（每吨）',
          label: '14401104|巴氏杀菌牛乳（蛋白质含量≥3.3%）（每吨）'
        },
        {
          value: '14401105|超高温灭菌羊乳（每吨）',
          label: '14401105|超高温灭菌羊乳（每吨）'
        },
        {
          value: '14401106|巴氏杀菌羊乳（每吨）',
          label: '14401106|巴氏杀菌羊乳（每吨）'
        },
        {
          value: '15000000|饮料、酒及酒精',
          label: '15000000|饮料、酒及酒精'
        },
        {
          value: '15100000|酒精',
          label: '15100000|酒精'
        },
        {
          value: '15200000|酒',
          label: '15200000|酒'
        },
        {
          value: '99999001|皮棉',
          label: '99999001|皮棉'
        },
        {
          value: '99999002|普梳棉纱',
          label: '99999002|普梳棉纱'
        },
        {
          value: '99999003|精梳棉纱（80支以下）',
          label: '99999003|精梳棉纱（80支以下）'
        },
        {
          value: '99999004|分离蛋白',
          label: '99999004|分离蛋白'
        },
        {
          value: '99999005|浓缩蛋白',
          label: '99999005|浓缩蛋白'
        },

        {
          value: '99999006|大豆原油',
          label: '99999006|大豆原油'
        },
        {
          value: '99999007|低温豆粕',
          label: '99999007|低温豆粕'
        },
        {
          value: '99999008|商品—水柠檬酸',
          label: '99999008|商品—水柠檬酸'
        },

        {
          value: '99999009|商品玉米淀粉',
          label: '99999009|商品玉米淀粉'
        },
        {
          value: '99999010|商品玉米胚芽',
          label: '99999010|商品玉米胚芽'
        },
        {
          value: '99999011|商品玉米纤维',
          label: '99999011|商品玉米纤维'
        },
        {
          value: '99999012|商品玉米蛋白',
          label: '99999012|商品玉米蛋白'
        },
        {
          value: '99999013|干茧',
          label: '99999013|干茧'
        },
        {
          value: '99999014|生丝',
          label: '99999014|生丝'
        },
        {
          value: '99999015|精梳棉纱（80支以上）',
          label: '99999015|精梳棉纱（80支以上）'
        },
        {
          value: '99999016|以购进小麦为原料生产磨制面粉',
          label: '99999016|以购进小麦为原料生产磨制面粉'
        },
        {
          value: '99999017|以购进水稻为原料生产大米',
          label: '99999017|以购进水稻为原料生产大米'
        },
        {
          value: '99999018|以购进生猪为原料生产分割猪肉',
          label: '99999018|以购进生猪为原料生产分割猪肉'
        },
        {
          value: '99999019|以购进生猪为原料生产白条猪',
          label: '99999019|以购进生猪为原料生产白条猪'
        },
        {
          value: '99999020|以购进毛鸡为原料生产西装鸡（分割鸡肉）',
          label: '99999020|以购进毛鸡为原料生产西装鸡（分割鸡肉）'
        },
        {
          value: '99999021|以购进毛鸡为原料生产白条鸡',
          label: '99999021|以购进毛鸡为原料生产白条鸡'
        },
        {
          value: '99999022|以购进毛鸭为原料生产白条鸭',
          label: '99999022|以购进毛鸭为原料生产白条鸭'
        },
        {
          value: '99999023|以购进毛鸭为原料生产分割鸭肉',
          label: '99999023|以购进毛鸭为原料生产分割鸭肉'
        },
        {
          value: '99999024|以购进毛鸡为原料生产扒鸡',
          label: '99999024|以购进毛鸡为原料生产扒鸡'
        },
        {
          value: '99999025|以购进鸭蛋为原料生产咸鸭蛋、松花蛋',
          label: '99999025|以购进鸭蛋为原料生产咸鸭蛋、松花蛋'
        },
        {
          value: '99999026|以购进鲜茶叶为原料生产精制茶',
          label: '99999026|以购进鲜茶叶为原料生产精制茶'
        },
        {
          value: '99999027|以购进牛皮为原料牛皮革',
          label: '99999027|以购进牛皮为原料牛皮革'
        },
        {
          value: '99999028|以购进羊皮为原料生产羊皮革',
          label: '99999028|以购进羊皮为原料生产羊皮革'
        },
        {
          value: '99999029|以购进原木为原料生产单板',
          label: '99999029|以购进原木为原料生产单板'
        },
        {
          value: '99999030|以购进原木为原料生产锯材',
          label: '99999030|以购进原木为原料生产锯材'
        },
        {
          value: '99999031|以购进原木为原料生产胶合板',
          label: '99999031|以购进原木为原料生产胶合板'
        },
        {
          value: '99999032|以购进枝丫柴、棉籽壳等为原料生产纤维板',
          label: '99999032|以购进枝丫柴、棉籽壳等为原料生产纤维板'
        },
        {
          value: '99999033|以购进枝丫柴为原料生产刨花板',
          label: '99999033|以购进枝丫柴为原料生产刨花板'
        },
        {
          value: '99999034|以购进原木为原料生产木制包装箱',
          label: '99999034|以购进原木为原料生产木制包装箱'
        },
        {
          value: '99999035|以购进原木为原料生产木托盘',
          label: '99999035|以购进原木为原料生产木托盘'
        },
        {
          value: '99999036|以购进玉米芯为原材料生产糠醛',
          label: '99999036|以购进玉米芯为原材料生产糠醛'
        },
        {
          value: '99999037|以购进中药材为原料生产中药饮片',
          label: '99999037|以购进中药材为原料生产中药饮片'
        },
        {
          value: '99999038|以购进皮棉为原料生产医用脱脂棉',
          label: '99999038|以购进皮棉为原料生产医用脱脂棉'
        },
        {
          value: '99999039|以购进农作物秸杆、三剩物、次小薪材等农林废弃物为原材料，生产生物质电力',
          label: '99999039|以购进农作物秸杆、三剩物、次小薪材等农林废弃物为原材料，生产生物质电力'
        },
        {
          value: '99999040|以购进果壳、木材等为原料生产活性炭',
          label: '99999040|以购进果壳、木材等为原料生产活性炭'
        },
        {
          value: '99999041|以购进蛋壳为原料生产彩蛋',
          label: '99999041|以购进蛋壳为原料生产彩蛋'
        },
        {
          value: '99999042|以购进动物肠衣为原料生产可吸收缝合线',
          label: '99999042|以购进动物肠衣为原料生产可吸收缝合线'
        },
        {
          value: '99999043|以购进驴皮（净干皮）为原料，生产东阿阿胶',
          label: '99999043|以购进驴皮（净干皮）为原料，生产东阿阿胶'
        },
        {
          value: '99999044|以购进大豆为原料生产膨化大豆',
          label: '99999044|以购进大豆为原料生产膨化大豆'
        },
        {
          value: '99999045|以购进活牛为原料生产分割牛肉',
          label: '99999045|以购进活牛为原料生产分割牛肉'
        },
        {
          value: '99999046|以购进活兔为原料生产分割兔肉(带骨）',
          label: '99999046|以购进活兔为原料生产分割兔肉(带骨）'
        },
        {
          value: '99999047|以购进活兔为原料生产分割兔肉(不带骨）',
          label: '99999047|以购进活兔为原料生产分割兔肉(不带骨）'
        },
        {
          value: '99999048|以购进活羊为原料生产分割羊肉',
          label: '99999048|以购进活羊为原料生产分割羊肉'
        },
        {
          value: '99999049|以购进鲜海鱼为原料生产冷冻整鱼',
          label: '99999049|以购进鲜海鱼为原料生产冷冻整鱼'
        },
        {
          value: '99999050|以购进生花生（带壳）为原料生产熟花生（带壳）',
          label: '99999050|以购进生花生（带壳）为原料生产熟花生（带壳）'
        },
        {
          value: '99999051|以购进生花生仁为原料生产熟花生仁',
          label: '99999051|以购进生花生仁为原料生产熟花生仁'
        },
        {
          value: '99999052|以购进带壳生花生为原料生产熟花生仁',
          label: '99999052|以购进带壳生花生为原料生产熟花生仁'
        },
        {
          value: '99999053|以购进生瓜子为原料生产熟瓜子',
          label: '99999053|以购进生瓜子为原料生产熟瓜子'
        },
        {
          value: '99999054|以购进羊毛为原料生产洗净毛',
          label: '99999054|以购进羊毛为原料生产洗净毛'
        },
        {
          value: '99999055|以购进羊毛为原料生产毛条',
          label: '99999055|以购进羊毛为原料生产毛条'
        },
        {
          value: '99999056|以购进羊毛为原料生产羊绒',
          label: '99999056|以购进羊毛为原料生产羊绒'
        },
        {
          value: '99999057|以购进兔毛为原料生产已梳兔毛',
          label: '99999057|以购进兔毛为原料生产已梳兔毛'
        },
        {
          value: '99999058|以购进原木为原料生产拼板',
          label: '99999058|以购进原木为原料生产拼板'
        },
        {
          value: '99999059|驴皮（去毛净干皮）阿胶',
          label: '99999059|驴皮（去毛净干皮）阿胶'
        },
        {
          value: '99999060|驴皮（带毛净干皮）阿胶',
          label: '99999060|驴皮（带毛净干皮）阿胶'
        },
        {
          value: '99999061|棉短绒特种纸浆',
          label: '99999061|棉短绒特种纸浆'
        },
        {
          value: '99999062|棉短绒高粘度浆',
          label: '99999062|棉短绒高粘度浆'
        },
        {
          value: '99999063|化纤浆',
          label: '99999063|化纤浆'
        },
        {
          value: '99999064|老豆腐',
          label: '99999064|老豆腐'
        },
        {
          value: '99999065|嫩豆腐',
          label: '99999065|嫩豆腐'
        },
        {
          value: '99999066|千张',
          label: '99999066|千张'
        },
        {
          value: '99999067|豆腐干',
          label: '99999067|豆腐干'
        },
        {
          value: '99999068|调味豆腐',
          label: '99999068|调味豆腐'
        },
        {
          value: '99999069|豆浆（调味豆浆）',
          label: '99999069|豆浆（调味豆浆）'
        },
        {
          value: '99999070|调味产品',
          label: '99999070|调味产品'
        },
        {
          value: '99999071|浓缩苹果清汁',
          label: '99999071|浓缩苹果清汁'
        },
        {
          value: '99999072|浓缩苹果浊汁',
          label: '99999072|浓缩苹果浊汁'
        },
        {
          value: '99999073|NFC苹果浊汁',
          label: '99999073|NFC苹果浊汁'
        },
        {
          value: '99999074|苹果浓缩浆',
          label: '99999074|苹果浓缩浆'
        },
        {
          value: '99999075|苹果原浆',
          label: '99999075|苹果原浆'
        },
        {
          value: '99999076|浓缩桃清汁',
          label: '99999076|浓缩桃清汁'
        },
        {
          value: '99999077|浓缩桃浊汁',
          label: '99999077|浓缩桃浊汁'
        },
        {
          value: '99999078|NFC桃浊汁',
          label: '99999078|NFC桃浊汁'
        },
        {
          value: '99999079|桃原浆',
          label: '99999079|桃原浆'
        },
        {
          value: '99999080|浓缩草莓清汁',
          label: '99999080|浓缩草莓清汁'
        },
        {
          value: '99999081|浓缩石榴清汁',
          label: '99999081|浓缩石榴清汁'
        },
        {
          value: '99999082|豌豆淀粉',
          label: '99999082|豌豆淀粉'
        },
        {
          value: '99999083|绿豆淀粉',
          label: '99999083|绿豆淀粉'
        },
        {
          value: '99999084|无水柠檬酸',
          label: '99999084|无水柠檬酸'
        },
        {
          value: '99999085|谷朊粉',
          label: '99999085|谷朊粉'
        },
        {
          value: '99999086|炒瓜子',
          label: '99999086|炒瓜子'
        },
        {
          value: '99999087|低粘度精制棉',
          label: '99999087|低粘度精制棉'
        },
        {
          value: '99999088|高粘度精制棉',
          label: '99999088|高粘度精制棉'
        },
        {
          value: '99999089|生物质压块（含生物质颗粒）',
          label: '99999089|生物质压块（含生物质颗粒）'
        },
        {
          value: '99999090|鱼胶原蛋白肽',
          label: '99999090|鱼胶原蛋白肽'
        },
        {
          value: '99999091|牛胶原蛋白肽',
          label: '99999091|牛胶原蛋白肽'
        },
        {
          value: '99999092|临沂金锣文瑞食品有限公司高温产品',
          label: '99999092|临沂金锣文瑞食品有限公司高温产品'
        },
        {
          value: '小米',
          label: '小米'
        },
        {
          value: '大米',
          label: '大米'
        },
        {
          value: '玉米|玉米',
          label: '玉米|玉米'
        },
        {
          value: ' 鲜蛋及冷藏蛋|鲜蛋及|冷藏蛋',
          label: ' 鲜蛋及冷藏蛋|鲜蛋及|冷藏蛋'
        },
        {
          value: '小麦|小麦',
          label: '小麦|小麦'
        },
        {
          value: '大豆|大豆',
          label: '大豆|大豆'
        },
      ],
      productOption2:[
        {
          value: '14401101|超高温灭菌牛乳（每吨）',
          label: '14401101|超高温灭菌牛乳（每吨）'
        },
        {
          value: '14401102|超高温灭菌牛乳（蛋白质含量≥3.3%）（每吨）',
          label: '14401102|超高温灭菌牛乳（蛋白质含量≥3.3%）（每吨）'
        },
        {
          value: '14401103|巴氏杀菌牛乳（每吨）',
          label: '14401103|巴氏杀菌牛乳（每吨）'
        },
        {
          value: '14401104|巴氏杀菌牛乳（蛋白质含量≥3.3%）（每吨）',
          label: '14401104|巴氏杀菌牛乳（蛋白质含量≥3.3%）（每吨）'
        },
        {
          value: '14401105|超高温灭菌羊乳（每吨）',
          label: '14401105|超高温灭菌羊乳（每吨）'
        },
        {
          value: '14401106|巴氏杀菌羊乳（每吨）',
          label: '14401106|巴氏杀菌羊乳（每吨）'
        },
        {
          value: '15000000|饮料、酒及酒精',
          label: '15000000|饮料、酒及酒精'
        },
        {
          value: '15100000|酒精',
          label: '15100000|酒精'
        },
        {
          value: '15200000|酒',
          label: '15200000|酒'
        },
        {
          value: '13300000|植物油及其制品',
          label: '13300000|植物油及其制品'
        },
        {
          value: 'N1500011|稻谷种植',
          label: 'N1500011|稻谷种植'
        },
        {
          value: 'N1500012|花卉种植',
          label: 'N1500012|花卉种植'
        },
        {
          value: 'N1500013|其他谷物种植',
          label: 'N1500013|其他谷物种植'
        },
        {
          value: 'N1500014|蔬菜种植',
          label: 'N1500014|蔬菜种植'
        },
        {
          value: 'N1500015|薯类种植',
          label: 'N1500015|薯类种植'
        },

        {
          value: 'N1500021|林木育苗',
          label: 'N1500021|林木育苗'
        },
        {
          value: 'N1500022|森林经营和管护',
          label: 'N1500022|森林经营和管护'
        },
        {
          value: 'N1500031|鸡的饲养',
          label: 'N1500031|鸡的饲养'
        },

        {
          value: 'N1500032|牛的饲养',
          label: 'N1500032|牛的饲养'
        },
        {
          value: 'N1500033|其他畜牧业',
          label: 'N1500033|其他畜牧业'
        },
        {
          value: 'N1500034|羊的饲养',
          label: 'N1500034|羊的饲养'
        },
        {
          value: 'N1500035|猪的饲养',
          label: 'N1500035|猪的饲养'
        },
        {
          value: 'N1500051|农产品初加工服务',
          label: 'N1500051|农产品初加工服务'
        },
        {
          value: 'N1500052|畜牧服务业',
          label: 'N1500052|畜牧服务业'
        },
        {
          value: 'N1500131|豆制品制造',
          label: 'N1500131|豆制品制造'
        },
        {
          value: 'N1500132|谷物磨制',
          label: 'N1500132|谷物磨制'
        },
        {
          value: 'N1500133|其他未列明农副食品加工',
          label: 'N1500133|其他未列明农副食品加工'
        },
        {
          value: 'N1500134|禽类屠宰',
          label: 'N1500134|禽类屠宰'
        },
        {
          value: 'N1500135|肉制品及副产品加工',
          label: 'N1500135|肉制品及副产品加工'
        },
        {
          value: 'N1500136|牲畜屠宰',
          label: 'N1500136|牲畜屠宰'
        },
        {
          value: 'N1500137|蔬菜加工',
          label: 'N1500137|蔬菜加工'
        },
        {
          value: 'N1500138|饲料加工',
          label: 'N1500138|饲料加工'
        },
        {
          value: 'N1500139|制糖业',
          label: 'N1500139|制糖业'
        },
        {
          value: 'N1500141|糕点、面包制造',
          label: 'N1500141|糕点、面包制造'
        },
        {
          value: 'N1500142|冷冻饮品及食用冰制造',
          label: 'N1500142|冷冻饮品及食用冰制造'
        },
        {
          value: 'N1500143|米、面制品制造',
          label: 'N1500143|米、面制品制造'
        },
        {
          value: 'N1500144|其他罐头食品制造',
          label: 'N1500144|其他罐头食品制造'
        },
        {
          value: 'N1500145|其他未列明食品制造',
          label: 'N1500145|其他未列明食品制造'
        },
        {
          value: 'N1500146|肉、禽类罐头制造',
          label: 'N1500146|肉、禽类罐头制造'
        },
        {
          value: 'N1500147|食品及饲料添加剂制造',
          label: 'N1500147|食品及饲料添加剂制造'
        },
        {
          value: 'N1500148|蔬菜、水果罐头制造',
          label: 'N1500148|蔬菜、水果罐头制造'
        },
        {
          value: 'N1500149|水产品罐头制造',
          label: 'N1500149|水产品罐头制造'
        },
        {
          value: 'N150014A|速冻食品制造',
          label: 'N150014A|速冻食品制造'
        },
        {
          value: 'N150014B|营养食品制造',
          label: 'N150014B|营养食品制造'
        },
        {
          value: 'N1500151|茶饮料及其他饮料制造',
          label: 'N1500151|茶饮料及其他饮料制造'
        },
        {
          value: 'N1500152|果菜汁及果菜汁饮料制造',
          label: 'N1500152|果菜汁及果菜汁饮料制造'
        },
        {
          value: 'N1500153|含乳饮料和植物蛋白饮料制造',
          label: 'N1500153|含乳饮料和植物蛋白饮料制造'
        },
        {
          value: 'N1500171|床上用品制造',
          label: 'N1500171|床上用品制造'
        },
        {
          value: 'N1500172|纺织带和帘子布制造',
          label: 'N1500172|纺织带和帘子布制造'
        },
        {
          value: 'N1500173|麻纤维纺前加工和纺纱',
          label: 'N1500173|麻纤维纺前加工和纺纱'
        },
        {
          value: 'N1500174|毛染整精加工',
          label: 'N1500174|毛染整精加工'
        },
        {
          value: 'N1500175|毛条和毛纱线加工',
          label: 'N1500175|毛条和毛纱线加工'
        },
        {
          value: 'N1500176|毛织造加工',
          label: 'N1500176|毛织造加工'
        },
        {
          value: 'N1500177|棉纺纱加工',
          label: 'N1500177|棉纺纱加工'
        },
        {
          value: 'N1500178|棉织造加工',
          label: 'N1500178|棉织造加工'
        },
        {
          value: 'N1500179|其他家用纺织制成品制造',
          label: 'N1500179|其他家用纺织制成品制造'
        },
        {
          value: 'N150017A|针织或钩针编织物织造',
          label: 'N150017A|针织或钩针编织物织造'
        },
        {
          value: 'N1500181|服饰制造',
          label: 'N1500181|服饰制造'
        },
        {
          value: 'N1500182|机织服装制造',
          label: 'N1500182|机织服装制造'
        },
        {
          value: 'N1500191|毛皮服装加工',
          label: 'N1500191|毛皮服装加工'
        },
        {
          value: 'N1500192|毛皮鞣制加工',
          label: 'N1500192|毛皮鞣制加工'
        },
        {
          value: 'N1500193|皮革服装制造',
          label: 'N1500193|皮革服装制造'
        },
        {
          value: 'N1500194|皮革鞣制加工',
          label: 'N1500194|皮革鞣制加工'
        },
        {
          value: 'N1500195|皮手套及皮装饰制品制造',
          label: 'N1500195|皮手套及皮装饰制品制造'
        },
        {
          value: 'N1500196|皮箱、包(袋)制造',
          label: 'N1500196|皮箱、包(袋)制造'
        },
        {
          value: 'N1500197|其他毛皮制品加工',
          label: 'N1500197|其他毛皮制品加工'
        },
        {
          value: 'N1500198|其他皮革制品制造',
          label: 'N1500198|其他皮革制品制造'
        },
        {
          value: 'N1500199|羽毛(绒)加工',
          label: 'N1500199|羽毛(绒)加工'
        },
        {
          value: 'N150019A|羽毛(绒)制品加工',
          label: 'N150019A|羽毛(绒)制品加工'
        },
        {
          value: 'N1500201|草及其他制品制造',
          label: 'N1500201|草及其他制品制造'
        },
        {
          value: 'N1500202|建筑用木料及木材组件加工',
          label: 'N1500202|建筑用木料及木材组件加工'
        },
        {
          value: 'N1500203|胶合板制造',
          label: 'N1500203|胶合板制造'
        },
        {
          value: 'N1500204|锯材加工',
          label: 'N1500204|锯材加工'
        },
        {
          value: 'N1500205|木片加工',
          label: 'N1500205|木片加工'
        },
        {
          value: 'N1500206|木制容器制造',
          label: 'N1500206|木制容器制造'
        },
        {
          value: 'N1500207|刨花板制造',
          label: 'N1500207|刨花板制造'
        },
        {
          value: 'N1500208|其他木材加工',
          label: 'N1500208|其他木材加工'
        },
        {
          value: 'N1500209|其他人造板制造',
          label: 'N1500209|其他人造板制造'
        },
        {
          value: 'N150020A|软木制品及其他木制品制造',
          label: 'N150020A|软木制品及其他木制品制造'
        },
        {
          value: 'N150020B|纤维板制造',
          label: 'N150020B|纤维板制造'
        },
        {
          value: 'N150020C|竹制品制造',
          label: 'N150020C|竹制品制造'
        },
        {
          value: 'N1500211|木质家具制造',
          label: 'N1500211|木质家具制造'
        },
        {
          value: 'N1500221|木竹浆制造',
          label: 'N1500221|木竹浆制造'
        },
        {
          value: 'N1500222|其他纸制品制造',
          label: 'N1500222|其他纸制品制造'
        },
        {
          value: 'N1500241|地毯、挂毯制造',
          label: 'N1500241|地毯、挂毯制造'
        },
        {
          value: 'N1500261|动物胶制造',
          label: 'N1500261|动物胶制造'
        },
        {
          value: 'N1500262|合成纤维单(聚合)体制造',
          label: 'N1500262|合成纤维单(聚合)体制造'
        },
        {
          value: 'N1500263|林产化学产品制造',
          label: 'N1500263|林产化学产品制造'
        },
        {
          value: 'N1500264|其他基础化学原料制造',
          label: 'N1500264|其他基础化学原料制造'
        },
        {
          value: 'N1500265|其他日用化学产品制造',
          label: 'N1500265|其他日用化学产品制造'
        },
        {
          value: 'N1500266|其他专用化学产品制造',
          label: 'N1500266|其他专用化学产品制造'
        },
        {
          value: 'N1500268|生物化学农药及微生物农药制造',
          label: 'N1500268|生物化学农药及微生物农药制造'
        },
        {
          value: 'N1500269|涂料制造',
          label: 'N1500269|涂料制造'
        },
        {
          value: 'N150026A|香料、香精制造',
          label: 'N150026A|香料、香精制造'
        },
        {
          value: 'N150026B|有机化学原料制造',
          label: 'N150026B|有机化学原料制造'
        },
        {
          value: 'N1500271|化学药品制剂制造',
          label: 'N1500271|化学药品制剂制造'
        },
        {
          value: 'N1500272|生物药品制造',
          label: 'N1500272|生物药品制造'
        },
        {
          value: 'N1500273|兽用药品制造',
          label: 'N1500273|兽用药品制造'
        },
        {
          value: 'N1500274|中成药生产',
          label: 'N1500274|中成药生产'
        },
        {
          value: 'N1500275|中药饮片加工',
          label: 'N1500275|中药饮片加工'
        },
        {
          value: 'N1500291|塑料板、管、型材制造',
          label: 'N1500291|塑料板、管、型材制造'
        },
        {
          value: 'N1500301|其他非金属矿物制品制造',
          label: 'N1500301|其他非金属矿物制品制造'
        },
        {
          value: 'N1500302|水泥制造',
          label: 'N1500302|水泥制造'
        },
        {
          value: 'N1500303|粘土砖瓦及建筑砌块制造',
          label: 'N1500303|粘土砖瓦及建筑砌块制造'
        },
        {
          value: 'N1500331|金属包装容器制造',
          label: 'N1500331|金属包装容器制造'
        },
        {
          value: 'N1500332|金属门窗制造',
          label: 'N1500332|金属门窗制造'
        },
        {
          value: 'N1500411|其他日用杂品制造',
          label: 'N1500411|其他日用杂品制造'
        },
        {
          value: 'N1500412|其他未列明制造业',
          label: 'N1500412|其他未列明制造业'
        },
        {
          value: 'N1500421|非金属废料和碎屑加工处理',
          label: 'N1500421|非金属废料和碎屑加工处理'
        },
        {
          value: 'N1500441|电力供应',
          label: 'N1500441|电力供应'
        },
        {
          value: 'N1500442|风力发电',
          label: 'N1500442|风力发电'
        },
        {
          value: 'N1500443|火力发电',
          label: 'N1500443|火力发电'
        },
        {
          value: 'N1500444|其他电力生产',
          label: 'N1500444|其他电力生产'
        },
        {
          value: 'N1500445|热力生产和供应',
          label: 'N1500445|热力生产和供应'
        },
        {
          value: 'N1500521|其他未列明零售业',
          label: 'N1500521|其他未列明零售业'
        },
        {
          value: 'N1500611|啤酒专用大麦芽（吨）',
          label: 'N1500611|啤酒专用大麦芽（吨）'
        },
        {
          value: 'N1500612|啤酒专用小麦芽（吨）',
          label: 'N1500612|啤酒专用小麦芽（吨）'
        },
        {
          value: 'N1500613|发酵乳（蛋白质量≥2.8%）',
          label: 'N1500613|发酵乳（蛋白质量≥2.8%）'
        },
        {
          value: 'N1500614|发酵乳（蛋白质量<2.8%）',
          label: 'N1500614|发酵乳（蛋白质量<2.8%）'
        },
        {
          value: 'N1500615|全脂乳粉',
          label: 'N1500615|全脂乳粉'
        },
        {
          value: 'N1500616|冷鲜带骨及去骨肉',
          label: 'N1500616|冷鲜带骨及去骨肉'
        },
        {
          value: 'N1500617|牛肉干',
          label: 'N1500617|牛肉干'
        },
        {
          value: 'N1500618|面粉',
          label: 'N1500618|面粉'
        },
        {
          value: 'N1500619|麸皮',
          label: 'N1500619|麸皮'
        },
        {
          value: 'N1500621|小米',
          label: 'N1500621|小米'
        },
        {
          value: 'N1500622|大米',
          label: 'N1500622|大米'
        },
        {
          value: 'N1500623|稻糠',
          label: 'N1500623|稻糠'
        },
        {
          value: 'N1500624|绵白糖',
          label: 'N1500624|绵白糖'
        },
        {
          value: 'N1500625|颗粒粕',
          label: 'N1500625|颗粒粕'
        },
        {
          value: 'N1500626|糖蜜',
          label: 'N1500626|糖蜜'
        },
        {
          value: 'N1500627|马铃薯淀粉',
          label: 'N1500627|马铃薯淀粉'
        },
        {
          value: 'N1500628|玉米淀粉',
          label: 'N1500628|玉米淀粉'
        },
        {
          value: 'N1500629|玉米蛋白',
          label: 'N1500629|玉米蛋白'
        },
        {
          value: 'N1500631|玉米胚芽',
          label: 'N1500631|玉米胚芽'
        },
        {
          value: 'N1500632|玉米纤维',
          label: 'N1500632|玉米纤维'
        },
        {
          value: 'N1500633|糠醛',
          label: 'N1500633|糠醛'
        },
        {
          value: 'N1500634|山羊无毛绒',
          label: 'N1500634|山羊无毛绒'
        },
        {
          value: 'N1500635|绵羊绒',
          label: 'N1500635|绵羊绒'
        },
        {
          value: 'N1500636|纱线',
          label: 'N1500636|纱线'
        },
        {
          value: 'N1500637|针纺织品',
          label: 'N1500637|针纺织品'
        },
        {
          value: 'N1500638|锯材',
          label: 'N1500638|锯材'
        },
        {
          value: 'N1500639|餐饮服务（粮、油、蛋、奶、水产品等）',
          label: 'N1500639|餐饮服务（粮、油、蛋、奶、水产品等）'
        },
        {
          value: 'N1500640|调制乳（蛋白质>=2.8%）',
          label: 'N1500640|调制乳（蛋白质>=2.8%）'
        },
        {
          value: 'N1500641|调制乳（蛋白质<2.8%）',
          label: 'N1500641|调制乳（蛋白质<2.8%）'
        },
        {
          value: 'N1500642|其他未列明批发业',
          label: 'N1500642|其他未列明批发业'
        },
        {
          value: 'N1500643|脱脂乳粉',
          label: 'N1500643|脱脂乳粉'
        },
        {
          value: '湿玉米|湿玉米',
          label: '湿玉米|湿玉米'
        },
        {
          value: '经加工的蛋类|经加工的蛋类',
          label: '经加工的蛋类|经加工的蛋类'
        },
        {
          value: '兽类、禽类、爬行动物类|兽类、禽类、爬行动物类',
          label: '兽类、禽类、爬行动物类|兽类、禽类、爬行动物类'
        },
        {
          value: '肉类生制品|肉类生制品',
          label: '肉类生制品|肉类生制品'
        },
        {
          value: '玉米|玉米',
          label: '玉米|玉米'
        },
        {
          value: ' 鲜蛋及冷藏蛋|鲜蛋及|冷藏蛋',
          label: ' 鲜蛋及冷藏蛋|鲜蛋及|冷藏蛋'
        },
        {
          value: '小麦|小麦',
          label: '小麦|小麦'
        },
        {
          value: '大豆|大豆',
          label: '大豆|大豆'
        },
      ],
      expStatus2: true,
      ncpList2:[{}],
      contentStyleObj:{}, //高度变化
    }
  },
  mounted(){
    // 获取窗口高度 
    this.contentStyleObj=this.$getHeight(167)
  },
  methods:{
    changeList(){
      if(!this.expStatus){
        return
      }else{
        setTimeout(()=>{
          this.ncpList.map(v=>{
          v.l6 = String((((v.l2*1*v.l3*1)+(v.l4*1*v.l5*1))/(v.l2*1+v.l4*1)).toFixed(2))
          v.l9 = String(((v.l7*1*v.l1*1*v.l6*1*v.l8*1)/(1+v.l8*1)).toFixed(2))
          })
        },10)
      }

      if(!this.expStatus2){
        return
      }else{
        setTimeout(()=>{
          this.ncpList2.map(v=>{
            v.l4 = v.l3 == '' || v.l3 == '0'?"":String((v.l2/v.l3).toFixed(6))
            v.l9 = String((((v.l5*1*v.l6*1)+(v.l7*1*v.l8*1))/(v.l5*1+v.l7*1)).toFixed(2))
            v.l11 = String((((v.l1/(1-v.l4))*v.l9*v.l10)/(1+v.l10*1)).toFixed(2))
          })
        },10)
      }
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb8',
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          if(res.data.data.info && res.data.data.info.length !=0){
            this.ncpList = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }else{
            this.ncpList= [{}]
          }
        })
        let param2 = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb11',
          comId:this.comId
        }
        gsInfo(param2).then(res=>{
          this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          if(res.data.data.info && res.data.data.info.length !=0){
            this.ncpList2 = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }else{
            this.ncpList2= [{
              name: "", 
              l1: '', 
              l2: '', 
              l3: '', 
              l4: '', 
              l5: '', 
              l6: '', 
              l7: '' ,
              l8: '' ,
              l9: '' ,
              l10: '' ,
              l11: '' 
            }]
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb8'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length !=0){
            this.ncpList = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }else{
            this.ncpList= [{}]
          }
        })
        gsInfo({tableName: 'gs_vat',itemName:'fb11'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length !=0){
            this.ncpList2 = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }else{
            this.ncpList2= [{
              name: "", 
              l1: '', 
              l2: '', 
              l3: '', 
              l4: '', 
              l5: '', 
              l6: '', 
              l7: '' ,
              l8: '' ,
              l9: '' ,
              l10: '' ,
              l11: '' 
            }]
          }
        })
      }
    },
    // 农产品进项税额计算 添加一行
    handleAddItem(item, index) {
      item.splice(index + 1, 0, {
        name: "", //产品名称
        hyName: "", // 耗用农产品名称
        l1: '', 
        l2: '', 
        l3: '', 
        l4: '', 
        l5: '', 
        l6: '', 
        l7: '' ,
        l8: '' ,
        l9: '' 
      });
    },
    // 农产品进项税额计算 删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },

     // 农产品直接销售进项税额计算 添加一行
     handleAddItem2(item, index) {
      item.splice(index + 1, 0, {
        name: "", //产品名称
        l1: '', 
        l2: '', 
        l3: '', 
        l4: '', 
        l5: '', 
        l6: '', 
        l7: '' ,
        l8: '' ,
        l9: '' ,
        l10: '' ,
        l11: '' 

      });
    },
    // 农产品直接销售进项税额计算 删除一行
    handleDelItem2(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    saveFb8(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb8',
          items:this.ncpList,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb8',
          items:this.ncpList
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    saveFb11(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb11',
          items:this.ncpList2,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb11',
          items:this.ncpList2
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new_input input{
  text-align: left !important;
}
.big_box4{
  // width: 98%;
  padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -4.15em;
    fill: currentColor;
    // position: absolute;
    left: 12px;
    top: 175px;
    font-size: 16px;
    color: var(--themeColor,#17a2b8);
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -4.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.content{
  // width: 2000px;
  width: 97%;
  // margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  width: 97%;
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
  padding-right: 15px;
}
.position_tr{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    // right:5px;
    // top:0;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
    padding-top: 6px;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.cent {
  display: flex;
}
</style>