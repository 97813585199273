<template>
  <el-tabs type="border-card" @tab-click="handleClick">
    <el-tab-pane label="增值税及附加税费申报表">
    <div class="right_box" v-if="!this.paramCheck.comId">
        <el-button type="primary" @click="download()" size="small" icon="FolderOpened">导出</el-button>
        <el-button size="small" @click="printingNew()" type="success" icon="Printer">打印</el-button>
      </div>
      <mainTable title="增值税及附加税费申报表" ref="maintable" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></mainTable>
    </el-tab-pane>
    <el-tab-pane label="附表(一)" v-if="showTable || this.paramCheck.comId">
      <fbOne ref="fbOne" title="增值税及附加税费申报表附列资料(一)(本期销售情况明细)" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbOne>
    </el-tab-pane>
    <el-tab-pane label="附表(二)" v-if="showTable || this.paramCheck.comId">
      <fbTwo ref="fbTwo" title="增值税及附加税费申报表附列资料(二)(本期进项税额明细)" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbTwo>
    </el-tab-pane>
    <el-tab-pane label="附表(三)" v-if="showTable || this.paramCheck.comId">
      <fbThree ref="fbThree" title="增值税及附加税费申报表附列资料(三)(服务、不动产和无形资产扣除项目明细)" :startAccountPeriod="startAccountPeriod" :comId="comId"></fbThree>
    </el-tab-pane>
    <el-tab-pane label="附表(四)" >
      <fbFour ref="fbFour" title="增值税及附加税费申报表附列资料(四)(税额抵减情况表)" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></fbFour>
    </el-tab-pane>
    <el-tab-pane label="附加税" >
      <fbFive ref="fbFive" title="增值税及附加税费申报表附列资料(五)(附加税费情况表)" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></fbFive>
    </el-tab-pane>
    <el-tab-pane label="减免申报明细表" v-if="showTable || this.paramCheck.comId">
      <mitigateTable ref="mitigateTable" title="增值税减免申报明细表" :startAccountPeriod="startAccountPeriod" :comId="comId" :source="source"></mitigateTable>
    </el-tab-pane>
    <el-tab-pane label="增值税补充申报表" v-if="showTable || this.paramCheck.comId">
      <replenish ref="replenish" title="增值税补充申报表" :startAccountPeriod="startAccountPeriod" :comId="comId"></replenish>
    </el-tab-pane>
    <el-tab-pane label="农产品" v-if="showTable || this.paramCheck.comId">
      <farmTable ref="farmTable" title="投入产出核定农产品增值税进项税额计算表" :startAccountPeriod="startAccountPeriod" :comId="comId"></farmTable>
    </el-tab-pane>
    <el-tab-pane label="农产品(汇总表)" v-if="showTable || this.paramCheck.comId">
      <deduct ref="deduct" title="农产品核定扣除增值税进项税额计算表(汇总表)" :startAccountPeriod="startAccountPeriod" :comId="comId"></deduct>
    </el-tab-pane>
    <el-tab-pane label="其他扣税凭证明细表" v-if="showTable || this.paramCheck.comId">
      <otherTable ref="otherTable" title="其他扣税凭证明细表" :startAccountPeriod="startAccountPeriod" :comId="comId"></otherTable>
    </el-tab-pane>

    <!-- 新表 成品油 加油站的 -->
    <el-tab-pane label="成品油明细表" v-if="showTable || this.paramCheck.comId">
      <finishOilTable ref="finishOilTable" title="成品油明细表" :startAccountPeriod="startAccountPeriod" :comId="comId"></finishOilTable>
    </el-tab-pane>

    <el-tab-pane label="加油站明细表" v-if="showTable || this.paramCheck.comId">
      <gasStationTable ref="gasStationTable" title="加油站明细表" :startAccountPeriod="startAccountPeriod" :comId="comId"></gasStationTable>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import mainTable from "./component/mainTable.vue"
import fbOne from "./component/fb1.vue"
import fbTwo from "./component/fb2.vue"
import fbThree from "./component/fb3.vue"
import fbFour from "./component/fb4.vue"
import fbFive from "./component/fb5.vue"
import mitigateTable from "./component/mitigateTable.vue"
import replenish from "./component/replenish.vue"
import farmTable from "./component/farmTable.vue"
import deduct from "./component/deduct.vue"
import otherTable from "./component/otherTable.vue"
import finishOilTable from "./component/finishOilTable.vue"
import gasStationTable from "./component/gasStationTable.vue"


import { report ,exportReport } from "@/api/printSet"

export default {
  name:'vatInvoice',
  props: {
    paramCheck:{
      default:{},
      type:Object
    },
  },
  components:{
    mainTable,
    fbOne,
    fbTwo,
    fbThree,
    fbFour,
    fbFive,
    mitigateTable,
    replenish,
    farmTable,
    deduct,
    otherTable,
    finishOilTable,
    gasStationTable
  },
  watch:{
    paramCheck(newValue,oldValue){
      this.comId = newValue.comId
      this.startAccountPeriod = newValue.period
      this.$nextTick(() => {
        this.getList()
      });
    },
    immediate:true,
    deep:true,
  },
  data(){
    return{
      startAccountPeriod:'',
      comId:0,
      type:'',
      showTable:this.$store.getters["user/comInfo"].comKj,
      param:{
        comIds:[this.$store.getters['user/comInfo'].comId],
        period:this.$store.getters['user/comInfo'].period,
        bookNames:['gs_vat']
      },
      source:""
    }
  },
  created(){
    if(this.paramCheck.comId){
      this.startAccountPeriod = this.paramCheck.period
      this.comId = this.paramCheck.comId
    }else{
      if(this.$store.getters["commons/params"].type){
        this.type = this.$store.getters["commons/params"].type
        this.startAccountPeriod = this.$store.getters["commons/params"].startAccountPeriod
        this.comId = this.$store.getters["commons/params"].comId;
        this.param.comIds = [this.$store.getters["commons/params"].comId];
        this.param.period = this.$store.getters["commons/params"].startAccountPeriod;
        this.param.itemName = 'swcsh'
        this.source = "swcsh"
      }
    }
    
  },
  methods:{
    handleClick(tab){
      if(tab.props.label == '增值税及附加税费申报表'){
        this.$refs.maintable.getList()
      }else if(tab.props.label == '附表(一)'){
        this.$refs.fbOne.getList()
      } else if(tab.props.label == '附表(二)'){
        this.$refs.fbTwo.getList()
      }else if(tab.props.label == '附表(三)'){
        this.$refs.fbThree.getList()
      }else if(tab.props.label == '附表(四)'){
        this.$refs.fbFour.getList()
      }else if(tab.props.label == '附加税'){
        this.$refs.fbFive.getList()
      }else if(tab.props.label == '减免申报明细表'){
        this.$refs.mitigateTable.getList()
      }else if(tab.props.label == '增值税补充申报表'){
        this.$refs.replenish.getList()
      }else if(tab.props.label == '农产品'){
        this.$refs.farmTable.getList()
      }else if(tab.props.label == '农产品(汇总表)'){
        this.$refs.deduct.getList()
      }else if(tab.props.label == '其他扣税凭证明细表'){
        this.$refs.otherTable.getList()
      }else if(tab.props.label == '成品油明细表'){
        this.$refs.finishOilTable.getList()
      }else if(tab.props.label == '加油站明细表'){
        this.$refs.gasStationTable.getList()
      }
    },
    printingNew(){
      report(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    download(){
      exportReport(this.param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    getList(){
      this.$refs.maintable.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.right_box {
  // float: right;
  width:25%;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 16px;
  position: absolute;
  top: 10px;
  right: 3px;
}
</style>