<template>
  <!-- 小规模增值税及附加税费申报表-->
  <div>
    <div class="big_box4">
      <div>
        <el-radio-group v-model="expStatus">
          <el-radio :label="true">使用公式</el-radio>
          <el-radio :label="false">不使用公式</el-radio>
        </el-radio-group>
      </div>
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content content_head" cellspacing="0">
        <tr class="top_bg">
          <td rowspan="2" colspan="2" class="center weight" style="width:32%">项目</td>
          <td rowspan="2" class="center weight" style="width:8%">栏次</td>
          <td colspan="2" class="center weight" style="width:30%">本期数</td>
          <td colspan="2" class="center weight" style="width:30%">本年累计</td>
        </tr>
        <tr class="top_bg">
          <td class="center" style="width:15%">货物及劳务</td>
          <td class="center" style="width:15%">服务、不动产和无形资产</td>
          <td class="center" style="width:15%">货物及劳务</td>
          <td class="center" style="width:15%">服务、不动产和无形资产</td>
        </tr>
      </table>

        <!-- 计税依据 -->
      <!-- <div class="fixed_box_table" :style="{height:contentStyleObj}"> -->
      <el-scrollbar :style="{height:contentStyleObj}">
        <table class="content content_body" cellspacing="0">
          <tr>
            <td style="width:6%" rowspan="15" class="bg_color">一、计税依据</td>
            <td style="width:26%" class="bg_color">（一） 应征增值税不含税销售额（3%征收率）</td>
            <td style="width:8%" class="bg_color">1</td>
            <!-- <qzf-str-td style="width:15%" :disabled="false"  v-model:amount="list[0].yzzzsbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:15%" :disabled="false"  v-model:amount="list[1].yzzzsbhsxse" @change="changeList()"></qzf-str-td> -->
            <qzf-str-td  :disabled="false" v-model:amount="list[0].yzzzsbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td  :disabled="false" v-model:amount="list[1].yzzzsbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:15%" :disabled="true" v-model:amount="list[2].yzzzsbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td style="width:15%" :disabled="true" v-model:amount="list[3].yzzzsbhsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">增值税专用发票不含税销售额</td>
            <td class="bg_color">2</td>
            <qzf-str-td  style="width:15%" :disabled="false" v-model:amount="list[0].swjgdkdzzszyfpbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td  style="width:15%" :disabled="false" v-model:amount="list[1].swjgdkdzzszyfpbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].swjgdkdzzszyfpbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].swjgdkdzzszyfpbhsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其他增值税发票不含税销售额</td>
            <td class="bg_color">3</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].skqjkjdptfpbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].skqjkjdptfpbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].skqjkjdptfpbhsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].skqjkjdptfpbhsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">（二）应征增值税不含税销售额（5%征收率）</td>
            <td class="bg_color">4</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].xsczbdcbhsxse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].xsczbdcbhsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">增值税专用发票不含税销售额</td>
            <td class="bg_color">5</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].swjgdkdzzszyfpbhsxse1" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].swjgdkdzzszyfpbhsxse1" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其他增值税发票不含税销售额</td>
            <td class="bg_color">6</td>
            <td class="center">----</td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].skqjkjdptfpbhsxse2" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].skqjkjdptfpbhsxse2" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">（三）销售使用过的固定资产不含税销售额</td>
            <td class="bg_color">7(7≥8)</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].xssygdysgdzcbhsxse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].xssygdysgdzcbhsxse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
          </tr>
          <tr>
            <td class="bg_color">其中：其他增值税发票不含税销售额</td>
            <td class="bg_color">8</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].skqjkjdptfpbhsxse1" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].skqjkjdptfpbhsxse1" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
          </tr>
          <tr>
            <td class="bg_color">（四）免税销售额</td>
            <td class="bg_color">9=10+11+12</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[0].msxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].msxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].msxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].msxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其中：小微企业免税销售额</td>
            <td class="bg_color">10</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].xwqymsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].xwqymsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].xwqymsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].xwqymsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">未达起征点销售额</td>
            <td class="bg_color">11</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].wdqzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].wdqzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].wdqzdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].wdqzdxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其他免税销售额</td>
            <td class="bg_color">12</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].qtmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].qtmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].qtmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].qtmsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">（五）出口免税销售额</td>
            <td class="bg_color">13(13≥14)</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].ckmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].ckmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].ckmsxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].ckmsxse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其中：其他增值税发票不含税销售额</td>
            <td class="bg_color">14</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].skqjkjdptfpxse1" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].skqjkjdptfpxse1" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].skqjkjdptfpxse1" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].skqjkjdptfpxse1" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">核定销售额</td>
            <td class="bg_color">15</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].hdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].hdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].hdxse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].hdxse" @change="changeList()"></qzf-str-td>
          </tr>
          <!-- 税款计算 -->
          <tr>
            <td rowspan="9" class="bg_color">二、税款计算</td>
            <td class="bg_color">本期应纳税额</td>
            <td class="bg_color">16</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].bqynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].bqynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].bqynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].bqynse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">核定应税额</td>
            <td class="bg_color">17</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].hdynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].hdynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].hdynse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].hdynse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">本期应纳税额减征额</td>
            <td class="bg_color">18</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].bqynsejze" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].bqynsejze" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].bqynsejze" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].bqynsejze" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">本期免税额</td>
            <td class="bg_color">19</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].bqmse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].bqmse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].bqmse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].bqmse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">其中：小微企业免税额</td>
            <td class="bg_color">20</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].xwqymse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].xwqymse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].xwqymse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].xwqymse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">未达起征点免税额</td>
            <td class="bg_color">21</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].wdqzdmse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].wdqzdmse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].wdqzdmse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].wdqzdmse" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">应纳税额合计</td>
            <td class="bg_color">22=16-18或17-18</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[0].ynsehj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].ynsehj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[2].ynsehj" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="true" v-model:amount="list[3].ynsehj" @change="changeList()"></qzf-str-td>
          </tr>
          <tr>
            <td class="bg_color">本期预缴税额</td>
            <td class="bg_color">23</td>
            <qzf-str-td :disabled="false" v-model:amount="list[0].bqyjse1" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="false" v-model:amount="list[1].bqyjse1" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>
          <tr>
            <td class="bg_color">本期应补（退）税额</td>
            <td class="bg_color">24=22-23</td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[0].bqybtse" @change="changeList()"></qzf-str-td>
            <qzf-str-td :disabled="expStatus" v-model:amount="list[1].bqybtse" @change="changeList()"></qzf-str-td>
            <td class="center">----</td>
            <td class="center">----</td>
          </tr>
          <!-- 附加税费 -->
        <tr>
          <td rowspan="3" class="bg_color">三、附加税费</td>
          <td class="bg_color">城市维护建设税本期应补（退）税额</td>
          <td class="bg_color">25</td>
          <qzf-str-td :colspan="2" :disabled="expStatus" v-model:amount="list[0].cjs" @change="changeList()"></qzf-str-td>
          <qzf-str-td :colspan="2" :disabled="true" v-model:amount="list[2].cjs" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">教育费附加本期应补（退）费额</td>
          <td class="bg_color">26</td>
          <qzf-str-td :colspan="2" :disabled="expStatus" v-model:amount="list[0].jyf" @change="changeList()"></qzf-str-td>
          <qzf-str-td :colspan="2" :disabled="true" v-model:amount="list[2].jyf" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="bg_color">地方教育附加本期应补（退）费额</td>
          <td class="bg_color">27</td>
          <qzf-str-td :colspan="2" :disabled="expStatus" v-model:amount="list[0].dfjyf" @change="changeList()"></qzf-str-td>
          <qzf-str-td :colspan="2" :disabled="true" v-model:amount="list[2].dfjyf" @change="changeList()"></qzf-str-td>
        </tr>
        
        </table>
      </el-scrollbar>
      <!-- </div> -->
    </div>

    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <div class="total_style">
        合计
        <el-tooltip content="本项=增值税+城市维护建设税+教育费附加+地方教育附加" placement="top">
          <i class="iconfont icon-gantanhao1"></i>
        </el-tooltip>
        <span>{{ totalAmount }}</span>元（{{$saveBig(totalAmount)}}）
      </div>
      <el-button @click="saveValues" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button @success="saveValues" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'mainTable',
  props: {
    title: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  computed:{
    totalAmount(){
      let sum = 0
      sum = Number(this.list[0].bqybtse) + Number(this.list[1].bqybtse) + Number(this.list[0].cjs) + Number(this.list[0].jyf) + Number(this.list[0].dfjyf)
      return sum?.toFixed(2)
    },
  },
  data() {
    return {
      expStatus: true,//是否使用公式
      list: [{},{},{},{}],
      contentStyleObj:{}, //高度变化
      taxNo: '',
      comName: '',
      durationOfTaxTime:''
    }
  },
  created() {
     this.contentStyleObj=this.$getHeight(380)
     this.init()
  },
  methods: {
    init() {
      // this.comName = this.$store.getters['user/comInfo'].comName
      // this.taxNo = this.$store.getters['user/comInfo'].taxNo
      this.getList()
    },
    changeList(){
      if(!this.expStatus){
        return
      }
      // （四）免税销售额
      this.list[0].msxse = String((this.list[0].xwqymsxse*1 + this.list[0].wdqzdxse*1 + this.list[0].qtmsxse*1).toFixed(2))
      this.list[1].msxse = String((this.list[1].xwqymsxse*1 + this.list[1].wdqzdxse*1 + this.list[1].qtmsxse*1).toFixed(2))
      // 应纳税额合计
      this.list[0].ynsehj = String((this.list[0].bqynse*1 - this.list[0].bqynsejze*1).toFixed(2))
      this.list[1].ynsehj = String((this.list[1].bqynse*1 - this.list[1].bqynsejze*1).toFixed(2))
      // 本期应补（退）税额
      this.list[0].bqybtse = String((this.list[0].ynsehj*1 - this.list[0].bqyjse1*1).toFixed(2))
      // if(this.list[0].bqybtse < 0){
      //   this.list[0].bqybtse = "0"
      // }
      this.list[1].bqybtse = String((this.list[1].ynsehj*1 - this.list[1].bqyjse1*1).toFixed(2))
      // if(this.list[1].bqybtse < 0){
      //   this.list[1].bqybtse = "0"
      // }
      //本年累计数
      this.list[2].yzzzsbhsxse = String((this.bfList[2].yzzzsbhsxse*1 - this.bfList[0].yzzzsbhsxse*1 + this.list[0].yzzzsbhsxse*1).toFixed(2))// 1
      this.list[3].yzzzsbhsxse = String((this.bfList[3].yzzzsbhsxse*1 - this.bfList[1].yzzzsbhsxse*1 + this.list[1].yzzzsbhsxse*1).toFixed(2))// 1

      this.list[2].swjgdkdzzszyfpbhsxse = String((this.bfList[2].swjgdkdzzszyfpbhsxse*1 - this.bfList[0].swjgdkdzzszyfpbhsxse*1 + this.list[0].swjgdkdzzszyfpbhsxse*1).toFixed(2))// 1
      this.list[3].swjgdkdzzszyfpbhsxse = String((this.bfList[3].swjgdkdzzszyfpbhsxse*1 - this.bfList[1].swjgdkdzzszyfpbhsxse*1 + this.list[1].swjgdkdzzszyfpbhsxse*1).toFixed(2))// 1

      this.list[2].skqjkjdptfpbhsxse = String((this.bfList[2].skqjkjdptfpbhsxse*1 - this.bfList[0].skqjkjdptfpbhsxse*1 + this.list[0].skqjkjdptfpbhsxse*1).toFixed(2))// 1
      this.list[3].skqjkjdptfpbhsxse = String((this.bfList[3].skqjkjdptfpbhsxse*1 - this.bfList[1].skqjkjdptfpbhsxse*1 + this.list[1].skqjkjdptfpbhsxse*1).toFixed(2))// 1

      this.list[3].xsczbdcbhsxse = String((this.bfList[3].xsczbdcbhsxse*1 - this.bfList[1].xsczbdcbhsxse*1 + this.list[1].xsczbdcbhsxse*1).toFixed(2))// 1

      this.list[3].swjgdkdzzszyfpbhsxse1 = String((this.bfList[3].swjgdkdzzszyfpbhsxse1*1 - this.bfList[1].swjgdkdzzszyfpbhsxse1*1 + this.list[1].swjgdkdzzszyfpbhsxse1*1).toFixed(2))// 1
      
      this.list[3].skqjkjdptfpbhsxse2 = String((this.bfList[3].skqjkjdptfpbhsxse2*1 - this.bfList[1].skqjkjdptfpbhsxse2*1 + this.list[1].skqjkjdptfpbhsxse2*1).toFixed(2))// 1

      this.list[2].xssygdysgdzcbhsxse = String((this.bfList[2].xssygdysgdzcbhsxse*1 - this.bfList[0].xssygdysgdzcbhsxse*1 + this.list[0].xssygdysgdzcbhsxse*1).toFixed(2))// 1

      this.list[2].skqjkjdptfpbhsxse1 = String((this.bfList[2].skqjkjdptfpbhsxse1*1 - this.bfList[0].skqjkjdptfpbhsxse1*1 + this.list[0].skqjkjdptfpbhsxse1*1).toFixed(2))// 1

      this.list[2].msxse = String((this.bfList[2].msxse*1 - this.bfList[0].msxse*1 + this.list[0].msxse*1).toFixed(2))// 1
      this.list[3].msxse = String((this.bfList[3].msxse*1 - this.bfList[1].msxse*1 + this.list[1].msxse*1).toFixed(2))// 1

      this.list[2].xwqymsxse = String((this.bfList[2].xwqymsxse*1 - this.bfList[0].xwqymsxse*1 + this.list[0].xwqymsxse*1).toFixed(2))// 1
      this.list[3].xwqymsxse = String((this.bfList[3].xwqymsxse*1 - this.bfList[1].xwqymsxse*1 + this.list[1].xwqymsxse*1).toFixed(2))// 1

      this.list[2].wdqzdxse = String((this.bfList[2].wdqzdxse*1 - this.bfList[0].wdqzdxse*1 + this.list[0].wdqzdxse*1).toFixed(2))// 1
      this.list[3].wdqzdxse = String((this.bfList[3].wdqzdxse*1 - this.bfList[1].wdqzdxse*1 + this.list[1].wdqzdxse*1).toFixed(2))// 1

      this.list[2].qtmsxse = String((this.bfList[2].qtmsxse*1 - this.bfList[0].qtmsxse*1 + this.list[0].qtmsxse*1).toFixed(2))// 1
      this.list[3].qtmsxse = String((this.bfList[3].qtmsxse*1 - this.bfList[1].qtmsxse*1 + this.list[1].qtmsxse*1).toFixed(2))// 1

      this.list[2].ckmsxse = String((this.bfList[2].ckmsxse*1 - this.bfList[0].ckmsxse*1 + this.list[0].ckmsxse*1).toFixed(2))// 1
      this.list[3].ckmsxse = String((this.bfList[3].ckmsxse*1 - this.bfList[1].ckmsxse*1 + this.list[1].ckmsxse*1).toFixed(2))// 1

      this.list[2].skqjkjdptfpxse1 = String((this.bfList[2].skqjkjdptfpxse1*1 - this.bfList[0].skqjkjdptfpxse1*1 + this.list[0].skqjkjdptfpxse1*1).toFixed(2))// 1
      this.list[3].skqjkjdptfpxse1 = String((this.bfList[3].skqjkjdptfpxse1*1 - this.bfList[1].skqjkjdptfpxse1*1 + this.list[1].skqjkjdptfpxse1*1).toFixed(2))// 1

      this.list[2].hdxse = String((this.bfList[2].hdxse*1 - this.bfList[0].hdxse*1 + this.list[0].hdxse*1).toFixed(2))// 1
      this.list[3].hdxse = String((this.bfList[3].hdxse*1 - this.bfList[1].hdxse*1 + this.list[1].hdxse*1).toFixed(2))// 1

      this.list[2].bqynse = String((this.bfList[2].bqynse*1 - this.bfList[0].bqynse*1 + this.list[0].bqynse*1).toFixed(2))// 1
      this.list[3].bqynse = String((this.bfList[3].bqynse*1 - this.bfList[1].bqynse*1 + this.list[1].bqynse*1).toFixed(2))// 1
      
      this.list[2].hdynse = String((this.bfList[2].hdynse*1 - this.bfList[0].hdynse*1 + this.list[0].hdynse*1).toFixed(2))// 1
      this.list[3].hdynse = String((this.bfList[3].hdynse*1 - this.bfList[1].hdynse*1 + this.list[1].hdynse*1).toFixed(2))// 1


      this.list[2].bqynsejze = String((this.bfList[2].bqynsejze*1 - this.bfList[0].bqynsejze*1 + this.list[0].bqynsejze*1).toFixed(2))// 1
      this.list[3].bqynsejze = String((this.bfList[3].bqynsejze*1 - this.bfList[1].bqynsejze*1 + this.list[1].bqynsejze*1).toFixed(2))// 1


      this.list[2].bqmse = String((this.bfList[2].bqmse*1 - this.bfList[0].bqmse*1 + this.list[0].bqmse*1).toFixed(2))// 1
      this.list[3].bqmse = String((this.bfList[3].bqmse*1 - this.bfList[1].bqmse*1 + this.list[1].bqmse*1).toFixed(2))// 1

      this.list[2].xwqymse = String((this.bfList[2].xwqymse*1 - this.bfList[0].xwqymse*1 + this.list[0].xwqymse*1).toFixed(2))// 1
      this.list[3].xwqymse = String((this.bfList[3].xwqymse*1 - this.bfList[1].xwqymse*1 + this.list[1].xwqymse*1).toFixed(2))// 1

      this.list[2].wdqzdmse = String((this.bfList[2].wdqzdmse*1 - this.bfList[0].wdqzdmse*1 + this.list[0].wdqzdmse*1).toFixed(2))// 1
      this.list[3].wdqzdmse = String((this.bfList[3].wdqzdmse*1 - this.bfList[1].wdqzdmse*1 + this.list[1].wdqzdmse*1).toFixed(2))// 1

      this.list[2].ynsehj = String((this.bfList[2].ynsehj*1 - this.bfList[0].ynsehj*1 + this.list[0].ynsehj*1).toFixed(2))// 1
      this.list[3].ynsehj = String((this.bfList[3].ynsehj*1 - this.bfList[1].ynsehj*1 + this.list[1].ynsehj*1).toFixed(2))// 1


      this.list[2].cjs = String((this.bfList[2].cjs*1 - this.bfList[0].cjs*1 + this.list[0].cjs*1).toFixed(2))// 1
      this.list[2].jyf = String((this.bfList[2].jyf*1 - this.bfList[0].jyf*1 + this.list[0].jyf*1).toFixed(2))// 1
      this.list[2].dfjyf = String((this.bfList[2].dfjyf*1 - this.bfList[0].dfjyf*1 + this.list[0].dfjyf*1).toFixed(2))// 1

    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_small_vat',
          period:this.startAccountPeriod,
          itemName:'main',
          comId:this.comId,
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.bfList = this.$qzfCopy(this.list)
          }
        })
      }else{
        gsInfo({tableName: 'gs_small_vat',itemName:'main'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
            this.durationOfTaxTime = res.data.data.time
            this.bfList = this.$qzfCopy(this.list)
          }
        })
      }
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_small_vat',
          itemName:'main',
          items:this.list,
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        param.init = this.source ? 'init' : ''
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_small_vat',
          itemName:'main',
          items:this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total_style{
  font-size: 14px;
  cursor: pointer;
  span{
    color: var(--themeColor,#17a2b8);
    margin: 0 3px;
  }
  .icon-gantanhao1{
    font-size: 14px;
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  //margin-top: 10px;
 
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  } 
}
.content_head{
  margin-top: 10px;
  border-bottom: none;
}
.content_body{
  border-top: none;
}
.left_k{
  text-indent: 2em;
}
.left_b{
  text-indent: 4em;
}
.left_x{
  text-indent:5em;
}
.left_d{
  text-indent:8em;
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>
