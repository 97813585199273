<template>
  <div class="big_box4">
    <div>
      <el-radio-group v-model="expStatus">
        <el-radio :label="true">使用公式</el-radio>
        <el-radio :label="false">不使用公式</el-radio>
      </el-radio-group>
    </div>
      
    <div class="top_title3">
      <h4>{{title}}</h4>
      <h5>一般纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <div class="add" @click="handleAddItem(list1)">
      <i class="iconfont icon-jiahao"></i>
    </div>
    <table class="content" cellspacing="0">
      <tr class="top_bg center">
        <td colspan="8">一、减税项目</td>
      </tr>
      <tr class="top_bg center">
        <td style="width:14%" rowspan="2"  colspan="2">减税性质代码及名称</td>
        <td  rowspan="2" style="width:6%">栏次</td>
        <td style="width:16%">期初余额</td>
        <td style="width:16%">本期发生额</td>
        <td style="width:16%">本期应抵减税额</td>
        <td style="width:16%">本期实际抵减税额</td>
        <td style="width:16%">期末余额</td>
      </tr>
      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3=1+2</td>
        <td>4≤3</td>
        <td>5=3-4</td>
      </tr>
      <tr>
        <td colspan="2" >合计</td>
        <td class="center bg_color">1</td>
        <td>{{ amount1 }}</td>
        <td>{{ amount2 }}</td>
        <td>{{ amount3 }}</td>
        <td>{{ amount4 }}</td>
        <td>{{ amount5 }}</td>
      </tr>
      <tr v-for="(item,index) in list1" :key="index" class="position_tr">
        <td colspan="2" >
          <jmxzdm type="gs_vat_js" v-model:id="item.jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="item.bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="item.qmye" @change="changeList()"></qzf-str-td>
        <span class="close" @click="handleDelItem(list1,index)">
          <i class="iconfont icon-shanchu"></i>
        </span>
      </tr>
      <tr v-if="list6.length>0">
        <td colspan="2" >
          <jmxzdm type="gs_vat_js" v-model:id="list6[0].jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" v-model:amount="list6[0].qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount="list6[0].bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount="list6[0].bqydjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" v-model:amount="list6[0].bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" v-model:amount="list6[0].qmye" @change="changeList()"></qzf-str-td>
      </tr>
    </table>
   
    <table  class="content" cellspacing="0">
      <div class="add2" @click="handleAddItem2(list4)">
      <i class="iconfont icon-jiahao"></i>
    </div>
      <tr class="top_bg center">
        <td colspan="9">二、免税项目</td>
      </tr>
      <tr class="top_bg center">
        <td rowspan="2"  colspan="2" style="width: 14%;">免税性质代码及名称</td>
        <td style="width: 6%;" rowspan="2">栏次</td>
        <td style="width: 15%;">免征增值税项目销售额</td>
        <td style="width: 15%;">免税销售额扣除项目本期实际扣除金额</td>
        <td style="width: 15%;">扣除后免税销售额</td>
        <td style="width: 15%;">免税销售额适用税率</td>
        <td style="width: 15%;">免税销售额对应的进项税额</td>
        <td style="width: 5%;">免税额</td>
      </tr>
      <tr class="top_bg center">
        <td>1</td>
        <td>2</td>
        <td>3=1-2</td>
        <td>--</td>
        <td>4</td>
        <td>5</td>
      </tr>
      <tr>
        <td colspan="2">合计</td>
        <td class="center bg_color">1</td>
        <td>{{ amount6 }}</td>
        <td>{{ amount7 }}</td>
        <td>{{ amount8 }}</td>
        <td>--</td>
        <td>{{ amount9 }}</td>
        <td>{{ amount10 }}</td>
      </tr>
      <tr>
        <td colspan="2" >出口免税</td>
        <td class="center bg_color">2</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="list2[0].qcye" @change="changeList()"></qzf-str-td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
        <td>--</td>
      </tr>

      <tr v-for="(item,index) in list4" :key="index" class="position_tr">
        <td colspan="2" >
          <jmxzdm type="gs_vat_ms" v-model:id="item.jmxzdmId"></jmxzdm>
        </td>
        <td class="center bg_color">4</td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.qcye" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.bqfse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="expStatus" type="text" v-model:amount="item.bqydjse" @change="changeList()"></qzf-str-td>
        <td>
          <el-select v-model="item.msxsesysl" placeholder="请选择" size="small" clearable style="width:100%">
            <el-option
              v-for="item in optionsSl"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.bqsjdjse" @change="changeList()"></qzf-str-td>
        <qzf-str-td :disabled="false" type="text" v-model:amount="item.qmye" @change="changeList()"></qzf-str-td>
        <span class="close1" @click="handleDelItem2(list4,index)">
          <i class="iconfont icon-shanchu"></i> 
        </span>
      </tr>
    </table>
  </div>
  <div class="save_box" v-if="$buttonStatus('swbb_bc')">
    <el-button @click="saveFb6" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
    <qzf-button @success="saveFb6" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'mitigateTable',
  props: {
    title: String,
    comId:{
      default:0,
      type:Number
    },
    startAccountPeriod:{
      default:'',
      type:String
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      expStatus: true,
      infojmb: [{},{},{},{}],
      contentStyleObj:{}, //高度变化
      comName:this.$store.getters['user/comInfo'].comName,
      durationOfTaxTime:'',
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      optionsSl:[{
        value: '0.13',
        label: '0.13'
      },{
        value: '0.09',
        label: '0.09'
      },{
        value: '0.06',
        label: '0.06'
      },{
        value: '0.05',
        label: '0.05'
      }],
      list1:[{}],
      list2:[{}],
      list6:[{}],
      list4:[{}]
    }
  },
  computed: {
    amount1(){
      let num = 0
      this.list1.forEach(item=>{
        num += item?.qcye*1
      })
      this.list6.forEach(item=>{
        num += item?.qcye*1
      })
      return num.toFixed(2)
    },
    amount2(){
      let num = 0
      this.list1.forEach(item=>{
        num += item?.bqfse*1
      })
      this.list6.forEach(item=>{
        num += item?.bqfse*1
      })
      return num.toFixed(2)
    },
    amount3(){
      let num = 0
      this.list1.forEach(item=>{
        num += item?.bqydjse*1
      })
      this.list6.forEach(item=>{
        num += item?.bqydjse*1
      })
      return num.toFixed(2)
    },
    amount4(){
      let num = 0
      this.list1.forEach(item=>{
        num += item?.bqsjdjse*1
      })
      this.list6.forEach(item=>{
        num += item?.bqsjdjse*1
      })
      return num.toFixed(2)
    },
    amount5(){
      let num = 0
      this.list1.forEach(item=>{
        num += item?.qmye*1
      })
      this.list6.forEach(item=>{
        num += item?.qmye*1
      })
      return num.toFixed(2)
    },
    amount6(){
      let num = 0
      this.list2.forEach(item=>{
        num += item?.qcye*1
      })
      this.list4.forEach(item=>{
        num += item?.qcye*1
      })
      return num.toFixed(2)
    },
    amount7(){
      let num = 0
      this.list2.forEach(item=>{
        num += item?.bqfse*1
      })
      this.list4.forEach(item=>{
        num += item?.bqfse*1
      })
      return num.toFixed(2)
    },
    amount8(){
      let num = 0
      this.list2.forEach(item=>{
        num += item?.bqydjse*1
      })
      this.list4.forEach(item=>{
        num += item?.bqydjse*1
      })
      return num.toFixed(2)
    },
    amount9(){
      let num = 0
      this.list2.forEach(item=>{
        num += item?.bqsjdjse*1
      })
      this.list4.forEach(item=>{
        num += item?.bqsjdjse*1
      })
      return num.toFixed(2)
    },
    amount10(){
      let num = 0
      this.list2.forEach(item=>{
        num += item?.qmye*1
      })
      this.list4.forEach(item=>{
        num += item?.qmye*1
      })
      return num.toFixed(2)
    },
  },
  created() {
    this.contentStyleObj=this.$getHeight(210)
  },
  // 销毁监听事件，从这个页面离开就销毁监听事件
  methods: {
    // 公式
    changeList(){
      if(!this.expStatus){
        return
      }
      setTimeout(()=>{
        this.list1.map(v=>{
          v.bqydjse = String(v.qcye*1 + v.bqfse*1)
          v.qmye = String(v.bqydjse*1 - v.bqsjdjse*1)
        })
        this.list6.map(v=>{
          v.bqydjse = String(v.qcye*1 + v.bqfse*1)
          v.qmye = String(v.bqydjse*1 - v.bqsjdjse*1)
        })
        this.list4.map(v=>{
          v.bqydjse = String(v.qcye*1 - v.bqfse*1)
        })
      },10)
    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_vat',
          period:this.startAccountPeriod,
          itemName:'fb6',
          comId:this.comId
        }
         param.init = this.source ? 'init' : ''
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list1 = res.data.data.info.filter(item => {
              return item.type == 1
            })
            this.list2 = res.data.data.info.filter(item => {
              return item.type == 2
            })
            this.list4 = res.data.data.info.filter(item => {
              return item.type == 4
            })
            this.list6 = res.data.data.info.filter(item => {
              return item.type == 6
            })
            // this.infojmb = res.data.data.info

            this.durationOfTaxTime = res.data.data.time
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_vat',itemName:'fb6'}).then(res=>{
          if(res.data.data.info){
            this.list1 = res.data.data.info.filter(item => {
              return item.type == 1
            })
            this.list2 = res.data.data.info.filter(item => {
              return item.type == 2
            })
            this.list4 = res.data.data.info.filter(item => {
              return item.type == 4
            })
            this.list6 = res.data.data.info.filter(item => {
              return item.type == 6
            })
            // this.infojmb = res.data.data.info
            this.durationOfTaxTime = res.data.data.time
          }
        })
      }
    },
    saveFb6(){
      let listZ = [...this.list1,...this.list6,...this.list2,...this.list4]
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_vat',
          itemName:'fb6',
          items:listZ,
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_vat',
          itemName:'fb6',
          items:listZ
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    handleAddItem(item) {
      item.push({
        type: 1,
        jmxzdm:"",
        qcye:"",
        bqfse:"",
        bqydjse:"",
        bqsjdjse:"",
        qmye:"",
        gsMainId:this.list6[0].gsMainId
      })
    },
    // 删除一行
    handleDelItem(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
    handleAddItem2(item) {
      item.push({
        type: 4,
        jmxzdm:"",
        // name: "", //产品名称
        qcye:"",
        bqfse:"",
        bqydjse:"",
        bqsjdjse:"",
        qmye:"",
        gsMainId:this.list6[0].gsMainId
      })
    },
     // 删除一行
     handleDelItem2(item, index) {
      if (item.length <= 1) {
        this.$message.error("仅剩一项禁止删除");
        return;
      }
      item.splice(index, 1);
    },
  }
}
</script>

<style lang="scss" scoped>
.big_box4{
  width: 98%;
  //padding: 0 15px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
  }
  input{
    width: 100%;
    line-height: 28px;
    padding: 0 3px;
    font-size: 13px;
    text-align: right;
  }
}
.right {
  text-align: right;
}
.center{
  text-align: center;
}
.save_box{
  margin: 0 auto;
  margin-top: 20px;
  text-align: right;
}
.position_tr{
  position: relative;
  .close,.close1 {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    margin-left: 5px;
    font-size: 20px;
    color: #ff1818;
   // padding-top: 6px;
    cursor: pointer;
    i {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.add {
  position: absolute;
  top: 226px;
  left: -25px;
  cursor: pointer;
  i {
    color: var(--themeColor,#17a2b8);
    font-weight: 900;
    font-size: 20px;
  }
}
.add2 {
  position: absolute;
  left: -25px;
  top: 131px;
  font-weight: 900;
  font-size: 20px;
  cursor: pointer;
  i {
    color: var(--themeColor,#17a2b8);
    font-weight: 900;
    font-size: 20px;
  }
}
</style>